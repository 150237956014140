<template>
  <div>
    <!-- 滚动图开始 -->
    <div class="scrollChart">
      <ScrollChart :scrImg="scrImg"></ScrollChart>
    </div>
    <!-- <div v-html="Img.records[1].content"></div> -->
    <!-- 滚动图结束 -->
    <!-- 内容开始 -->
    <div class="contentbotton">
      <!-- 左侧菜单开始 -->
      <div class="left">
        <menuLeft @gettype="gettype" :formdata="formdata"></menuLeft>
        <!-- <Leftmenu @gettype="gettype"></Leftmenu> -->
      </div>
      <!-- 左侧菜单结束 -->
      <!-- 右侧列表开始 -->
      <div class="right">
        <router-view></router-view>
      </div>
    </div>
    <!-- 内容结束 -->
  </div>
</template>

<script>
import {
  pageAdvertisementInfo, //分页
  getAdvertisementDetail, //详情
} from "@/api";
// import Contantus from "@/views/SecondPage/contactus";
import ScrollChart from "@/components/scrollChart";
import menuLeft from "@/components/menu";
import Leftmenu from "./components/leftmenu";
export default {
  name: "information",
  components: {
    ScrollChart,
    Leftmenu,
    menuLeft,
  },
  data() {
    return {
      scrImg: [],
      formdata: {
        topText: "旭德简介",
        menulist: [
          { path: "/information/introduction", title: "旭德教育" },
          { path: "/information/distribution", title: "网点分布" },
          { path: "/information/video", title: "宣传视频" },
          { path: "/information/CompanyStatic", title: "公司动态" },
          { path: "/information/contactus", title: "联系我们" },
        ],
      },
      type: "1",
      videolist: [], //网课列表
    };
  },
  mounted() {
    // this.$loginDialog();
    console.log(this.$loginDialog);
    // this.$loginDialog.gologin();
    this.getTop();
  },
  methods: {
    // 顶部轮博图
    async getTop() {
      let res = await getAdvertisementDetail({ id: 2 });
      if (res.code == 1) {
        this.scrImg = res.data.images;
      }
    },
    gettype(type) {
      this.type = type;
      if (type == "/information/introduction") {
        // 简介
        this.$router.push("/information/introduction");
      }
      if (type == "2") {
        // 网络课程
      }
      if (type == "/information/video") {
        // 宣传视频
        this.$router.push("/information/video");
      }
      if (type == "/information/CompanyStatic") {
        // 公司动态
        this.$router.push("/information/CompanyStatic");
      }
      if (type == "/information/distribution") {
        // 网点分布
        this.$router.push("/information/distribution");
      }
      if (type == "/information/contactus") {
        // 联系我们
        this.$router.push("/information/contactus");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.right {
  width: 910px;
  // height: 730px;
  // overflow: auto;
  // padding: 0 30px;
  margin-left: 30px;
  .title {
    width: 910px;
    height: 106px;
    font-size: 26px;
    color: #222222;
    line-height: 106px;
    text-align: center;
  }
  .rightcontent {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    .videodiv {
      width: 220px;
      margin: 25px 40px;
      height: 170px;
      background-color: #e6e6e6;
    }
  }
}
.scrollChart {
  width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  margin-bottom: 40px;
}
.contentbotton {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
  // margin-top: 30px;
}
</style>
